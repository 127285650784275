<template>
    <div class="calendar_list_page">
        <b-input placeholder="Search..."
            autofocus
            v-model="search"
            type="text"
            icon="magnify"
            icon-clickable
            :icon-right="search.length != 0? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="search = ''">
        </b-input>
        <div v-if="!isShowSpinner">
            <div class="calendar_items_cont" v-if="calendars.length > 0">
                <div class="calendar_item"
                    v-for="(calendar, index) in calendars"
                    :key="`calendar__${index}`"
                    @click="() => goToCalendar(calendar.uid)"
                >   
                    <div class="calendar__image"></div>
                    <div class="calendar__info">
                        <div class="calendar__title">{{calendar.title}}</div>
                        <div class="calendar__description">{{calendar.description}}</div>
                    </div>    
                </div>
            </div>
            <div class="empty" v-else>
                Calendars not found
            </div>
        </div>
        <div v-else>
            <div class="spinner"></div>
        </div>
    </div>
</template>

<script>
import router from '../router'
import CMS from '../service/cms/service'
import MainLayout from './MainLayout.vue'

import '../../public/spinner.css'

export default {
    name: 'CalendarList',
    components:{
        MainLayout
    },
    watch:{
        search(newValue) {
            this.updateList()

            let query = {
                q: newValue,
            } 

            if (!this.$route.query ||
                this.$route.query && this.$route.query.q != newValue)
            {
                this.$router.replace({
                    name: this.$route.name,
                    query: newValue.length > 0 ?
                        query : null
                })
            } 
        }
    },
    created() {
        this.search = this.$route.query.q ?? ""
        this.updateList()
    },
    data() {
        return {
            isShowSpinner: false,
            calendars: [],
            search: "",
            limit: 10,
            offset: 0,
        }
    },
    methods: {
        updateList(){
            this.isShowSpinner = true
            CMS.calendar.listBySearch(this.search, this.offset, this.limit)
            .then(data => {
                if (data && data.err){
					throw data.err
				}
                if (Array.isArray(data) || data == null){
                    this.calendars = data || []
                }
            })
            .finally( _ => {
                this.isShowSpinner = false
            })
        },
        clearSearch(){
            this.search = ""
        },
        goToCalendar(uid){
            router.push({name: "calendar", params: {calendarUid: uid}})
        }
    },
}
</script>

<style scoped>
.calendar_list_page{
    padding: 16px;
    margin: 16px 0 40px 0;

    display: flex;
    flex-direction: column;
    gap: 16px;
}
.calendar_items_cont{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.calendar_item{
    display: grid;
    grid-template-columns: 100px calc(100% - 100px - 10px);
    grid-column-gap: 10px;
}
.calendar__image{
    height: 100px;
    width: 100px;
    border: 1px solid black;
    border-radius: 75px;
}
.calendar__info{
    height: 100px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.calendar__title{
    font-size: 18px;
    font-weight: 600;
}
.calendar__description{
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3;
    -webkit-box-orient: vertical;
}

.calendar__search{
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: calc(100% - 26px - 10px) 26px;
    grid-column-gap: 10px;
    border: 1px solid black;
    border-radius: 3px;
}

.calendar__search > input{
    border: none;
}

.calendar__search > input:focus{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

input:focus, textarea:focus{
    outline: none;
}

input.disabled{
    background: #e9e9e9;
    color: grey;
}


input {
    padding: 6px 6px 7px 6px;
    width: calc(100% - 20px);
    font-size: 16px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
}

.empty{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.icon{
    display: flex;
    align-items: center;
}
</style>